import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins:[createPersistedState()],
  state: {
    campusInfo: {
      store_id: null,
      name: '',
      short_name: ''
    },
    category:''
  },
  getters: {
  },
  mutations: {
    updateInfo(state, payload) {
      state.campusInfo = payload
    },
    updateCategory(state, payload) {
      state.category = payload
    }
  },
  actions: {
    // logout({ commit }) {
    //   commit('logout')
    // }
  },
  modules: {
  }
})
