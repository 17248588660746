import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css';
const app = createApp(App);
let imgPrefix = ''
if (window.location.origin.includes('localhost') || window.location.origin.includes('.xyz')) {
    imgPrefix = 'https://show.eliteu.xyz/'
} else {
    imgPrefix = `${window.location.origin}/`
}
app.config.globalProperties.$imgPrefix = imgPrefix;
app.use(store).use(router).use(Antd).mount('#app')
