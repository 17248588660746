<template>
    <div class="home">
        <div class="container nav-list">
            <h1>英荔互动项目平台</h1>
            <div class="flex">
                <a v-for="(nav,index) in navList" :key="index" :href="nav.href">
                    <img :src="nav.cover" alt="">
                </a>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
const p1 = require('@/assets/images/1.png')
const p2 = require('@/assets/images/2.png')
export default defineComponent({
    name: 'HomeView',
    setup() {
        const origin = window.location.origin;
        const aiShowUrl = origin.includes('.xyz') ? 'https://aishow.eliteu.xyz/' : 'https://aishow.aimaker.space/'
        const navList = [
            {
                href: aiShowUrl,
                cover: p1
            },
            {
                href: `${origin}/blocks-experience/category`,
                cover: p2
            }
        ]
        return {
            aiShowUrl,
            navList
        }
    }
});
</script>
<style lang="scss" scoped>
        .nav-list {
            text-align: center;
            padding-top: 97px;
        }
        h1 {
            font-weight: 600 !important;
        }

        .nav-list>.flex {
            display: flex;
            justify-content: center;
        }

        .nav-list>.flex a>img {
            width: 398px;
            border-radius: 35px;
            border: 7px solid transparent;
            margin: 60px 20px;
        }

        .nav-list>.flex a>img:hover {
            border-color: #83fff8;
            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
        }
</style>