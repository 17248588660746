<template>
    <div class="login">
        <a-spin size="large" :spinning="loading">
            <div class="login-form">
                <h3>创造乐园门店 <br> 图形化编程作品</h3>
                <a-form :model="formState" name="basic" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }"
                    autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed" class="form">
                    <a-form-item name="store_no" :rules="[{ required: true, message: '请输入门店 ID' }]">
                        <a-input v-model:value="formState.store_no" placeholder="门店 ID" :style="{ height: '40px', padding:'4px 14px' }" />
                    </a-form-item>

                    <a-form-item name="store_password" :rules="[{ required: true, message: '请输入密码' }]">
                        <a-input-password placeholder="密码" v-model:value="formState.store_password"
                            :style="{ height: '40px', padding:'4px 14px' }" />
                    </a-form-item>

                    <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
                        <a-button type="primary" html-type="submit" :style="{ width: '100%', height: '40px' }"
                            :disabled="isAgree">登录</a-button>
                    </a-form-item>
                    <p>忘记密码时请联系英荔客服专员</p>

                    <a-form-item name="agree" :wrapper-col="{ offset: 0, span: 24 }">
                        <div class="agree">
                            <a-checkbox v-model:checked="formState.agree"></a-checkbox>
                            <span>本平台仅限英荔教育集团内部使用。为确保正常运行，会调用计算机的网络适配器、扬声器、麦克风、摄像头、蓝牙设备等并收集相关使用信息。</span>
                        </div>
                    </a-form-item>
                </a-form>
            </div>
        </a-spin>
        <video ref="video" autoplay id="video"></video>
        <canvas ref="canvas" id="canvas"></canvas>
    </div>
</template>

<script lang="ts">
interface FormState {
    store_no: string
    store_password: string
    login_type?: number
    agree?: boolean
}
import { defineComponent, reactive, ref, watch } from 'vue';
import Cookies from "js-cookie";
import { storeLogin, deviceLoginLog } from '@/api/index'
import store from '@/store';
import { message } from 'ant-design-vue'
import router from '@/router';
const loginResMap: any = {
    203: "门店 ID 或密码错误",
    204: "当前校区已经运行了 1 个 AI 互动游戏平台，无法在另一台计算机上继续进入。请先关闭正在运行的平台，稍候 1 分钟再试。",
    205: "session 不存在",
    403: "您无权登录",
    207: "当前校区还未开通图形化编程作品平台哦"
}
export default defineComponent({
    name: 'LoginView',
    setup() {
        sessionStorage.setItem('store_token', '')
        sessionStorage.setItem('isLogin', '')
        Cookies.remove('scratchToken',{ domain: '.aimaker.space' })
        Cookies.remove('scratchToken',{ domain: '.eliteu.xyz' })
        Cookies.remove('scratchToken',{ domain: 'localhost' })
        const loading = ref<boolean>(false)
        const formState = reactive<FormState>({
            store_no: '',
            store_password: '',
            agree: true,
        });
        const takePhoto = async () => {
            let user_photo = null
            try {
                const video = document.getElementById("video") as HTMLVideoElement
                const canvas = document.getElementById("canvas") as HTMLCanvasElement
                const stream = await navigator.mediaDevices.getUserMedia({ video: true })
                video.srcObject = stream;
                await video.play();
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    user_photo = canvas.toDataURL()
                    stream.getTracks().forEach(track => {
                        track.stop(); // 停止轨道
                    });
                }
            } catch (error) {
                console.log('摄像头未授权');
                user_photo = null
            }
            return user_photo
        };
        const getDeviceName = () => {
            const userAgent = navigator.userAgent || navigator.vendor;
            if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
                return 'iOS';
            } else if (userAgent.match(/Android/i)) {
                return 'Android';
            } else if (userAgent.match(/webOS/i) || userAgent.match(/Palm/i)) {
                return 'Palm';
            } else if (userAgent.match(/BlackBerry/i)) {
                return 'BlackBerry';
            } else if (userAgent.match(/Windows Phone/i)) {
                return 'Windows Phone';
            } else if (userAgent.match(/SymbianOS/i)) {
                return 'Symbian';
            } else if (userAgent.match(/Tablet/i) && !userAgent.match(/iPad/i)) {
                return 'Tablet';
            } else if (userAgent.match(/Mobile/i) && !userAgent.match(/iPhone/i) && !userAgent.match(/Android/i)) {
                return 'Mobile';
            } else {
                return 'Desktop';
            }
        }
        const getBroswerName = () => {
            var nAgt = navigator.userAgent;
            var browserName = navigator.appName;
            var nameOffset, verOffset, ix;

            // In Opera 15+, the true version is after "OPR/" 
            if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
                browserName = "Opera";
            }
            // In older Opera, the true version is after "Opera" or after "Version"
            else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
                browserName = "Opera";
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
                browserName = "Microsoft Internet Explorer";
            }
            // In Chrome, the true version is after "Chrome" 
            else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
                browserName = "Chrome";
            }
            // In Safari, the true version is after "Safari" or after "Version" 
            else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
                browserName = "Safari";
            }
            // In Firefox, the true version is after "Firefox" 
            else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
                browserName = "Firefox";
            }
            // In most other browsers, "name/version" is at the end of userAgent 
            else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
                (verOffset = nAgt.lastIndexOf('/'))) {
                browserName = nAgt.substring(nameOffset, verOffset);
                if (browserName.toLowerCase() == browserName.toUpperCase()) {
                    browserName = navigator.appName;
                }
            }
            return browserName
        }
        const onFinish = (values: any) => {
            values.login_type = 1
            loading.value = true
            storeLogin(values).then(async res => {
                const code = res.data.code as any
                const content = loginResMap[code]
                const token = res.data.store_token
                if (content) {
                    loading.value = false
                    message.error(content);
                    return
                }
                store.commit('updateInfo', res.data)
                sessionStorage.setItem('isLogin', 'true')
                sessionStorage.setItem('store_token', token)
                Cookies.set('scratchToken', token, { domain: '.eliteu.xyz', expires: 1 })
                Cookies.set('scratchToken', token, { domain: '.aimaker.space', expires: 1 })
                Cookies.set('scratchToken', token, { domain: 'localhost', expires: 1 })
                const user_photo = await takePhoto()
                await deviceLoginLog({
                    platform_type: 'Scratch',
                    device_name: getDeviceName(),
                    operating_system: navigator.platform.toLowerCase(),
                    browser: getBroswerName(),
                    user_photo: user_photo
                })
                loading.value = false
                await router.replace('/blocks-experience/category')
            })
        };

        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
        };
        const isAgree = ref(false)
        watch(() => formState.agree, (val) => {
            isAgree.value = !val
        })
        return {
            formState,
            isAgree,
            loading,
            takePhoto,
            onFinish,
            onFinishFailed
        }
    },
});
</script>
<style lang="scss" scoped>
.login-form {
    width: 410px;
    height: 464px;
    background: #FFFFFF;
    border-radius: 10px;
    margin: 122px auto 0;
    padding: 32px 40px 37px;
    font-style: 16px;

    h3 {
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        color: #333333;
        margin-bottom: 27px;
        text-align: center;
    }

    p {
        margin-top: -10px;
        margin-bottom: 18px;
        text-align: right;
        color: #999999;
    }

    .form {
        .agree {
            display: flex;
            align-items: flex-start;
            color: #999999;

            span {
                margin-left: 3px;
                text-align: justify;
            }
        }
    }
}

#canvas,
#video {
    opacity: 0;
    position: fixed;
}
</style>