<template>
    <div class="header">
        <div class="container">
          <img alt="Vue logo" class="logo" src="../assets/logo.png">
        </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
export default defineComponent({
    name: 'HeaderCom',
    props: {
        name: String
    },
});
  </script>
  
  <style scoped lang="scss">
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 96px;
    background: #fff;
    box-shadow: -4px -13px 11px 4px #080000;
    z-index: 3;
    
  .container {
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .logo {
        width: 274px;
        height: 53px;
      }
    }
  }

</style>
  