import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_router_view),
    (_ctx.showLink)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("a", {
            href: "https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/b99bb68e1397757892904130761/v.f40672.mp4",
            target: "_blank"
          }, "图形化编程作品平台入门教程", -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}