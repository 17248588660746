import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import HomeView from '../views/HomeView.vue'
import Category from '../views/Category.vue'
import Works from '../views/Works.vue'
import store from '@/store'
const prefix = '/blocks-experience'

const routes: Array<RouteRecordRaw> = [
  {
    path: prefix + '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.state.campusInfo.store_id) {
        next(prefix + '/')
      } else {
        next()
      }
    }
  },
  {
    path: prefix + '/category',
    name: 'category',
    component: Category,
    beforeEnter: (to, from, next) => {
      if (!store.state.campusInfo.store_id) {
        next(prefix + '/login')
      } else {
        next()
      }
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: prefix + '/category',
    name: 'category',
    component: Category,
    beforeEnter: (to, from, next) => {
      if (!store.state.campusInfo.store_id) {
        next(prefix + '/login')
      } else {
        next()
      }
    }
  },
  {
    path: prefix +'/',
    name: 'index-category',
    redirect:prefix + '/category',
  },
  {
    path: prefix + '/works/:category',
    name: 'works',
    component: Works
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if(to.path.includes(prefix)){
      window.document.title = '英荔图形化编程作品平台'
  }
  next()
})
export default router
