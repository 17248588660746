<template>
    <div class="work">
        <div class="title">
            <div class="container">
                <div class="campus">
                    <a-button class="back" type="text" :icon="h(LeftOutlined)" @click="$router.back()">返回上一页</a-button>
                    <h3>{{ campusInfo.name }} - {{ curCategoryName }}作品</h3>
                </div>
                <div class="tag-box">
                    <div class="last" v-if="showHandleBtn" @click="handleScroll('-')">
                        <img :src="curHandleStatu == '-' ? leftActive : leftSrc" alt="" class="left-img">
                    </div>
                    <a-spin size="small" :spinning="tagLoading">
                        <div :style="{ padding: showHandleBtn ? '0 20px' : '0' }">
                            <div class="tag-list">
                                <div class="tags" :style="{ right: `${tagRight}px` }">
                                    <div :class="curTagId == tag.tag_id ? 'tag active' : 'tag'" v-for="tag in tags"
                                        :key="tag.tag_id" @click="fetchCategoryWorks(tag.tag_id)">{{ tag.name }}</div>
                                </div>
                            </div>
                        </div>
                    </a-spin>

                    <div class="next" v-if="showHandleBtn" @click="handleScroll('+')">
                        <img :src="curHandleStatu == '+' ? rightActive : rightSrc" alt="" class="right-img">
                    </div>
                </div>
            </div>
        </div>
        <div class="works-box">
            <a-spin size="large" :spinning="worksLoading">
                <div class="works">
                    <div class="work" v-for="work in works" :key="work.id" @click="selectWork(work.id)">
                        <img :src="work.work_image" alt="">
                        <p>{{ work.name }}</p>
                    </div>
                </div>
            </a-spin>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, h, ref, onBeforeMount, nextTick, reactive, getCurrentInstance, ComponentInternalInstance } from 'vue';
import { LeftOutlined } from '@ant-design/icons-vue';
const left = require('@/assets/images/left.png')
const left_active = require('@/assets/images/left_active.png')
const right = require('@/assets/images/right.png')
const right_active = require('@/assets/images/right_active.png')
import { useRoute } from 'vue-router';
import { getCategoryWorks } from '@/api/index'
import { Tag, Work, Campus } from '@/types/category'
import store from '@/store';
const target = window.location.origin.includes('.space') ? 'https://make.aimaker.space/' :'https://make.dev.longan.eliteu.xyz/';
export default defineComponent({
    name: 'WorksView',
    components: {
    },
    setup() {
        const app = getCurrentInstance() as ComponentInternalInstance
        const imgPrefix = app.appContext.config.globalProperties.$imgPrefix;
        const leftSrc = ref(left)
        const leftActive = ref(left_active)
        const rightSrc = ref(right)
        const rightActive = ref(right_active)
        const route = useRoute()
        const curCategory = route.params.category;
        const curTagId = ref<number | string>('')
        const tags = ref<Tag[]>([])
        const works = ref<Work[]>([])
        const tagLoading = ref<boolean>(true)
        const worksLoading = ref<boolean>(true)
        const showHandleBtn = ref<boolean>(false)
        const tagRight = ref<number>(0)
        const tagListWidth = ref<number>(0)
        const curHandleStatu = ref<string>('')
        const campusInfo = reactive<Campus>(store.state.campusInfo)
        const curCategoryName = ref<string>(store.state.category)
        const fetchCategoryWorks = async (tag_id: number | string) => {
            if (curTagId.value == tag_id) {
                return
            }
            worksLoading.value = true
            works.value = []
            tagListWidth.value = 0
            try {
                curTagId.value = tag_id
                let res = await getCategoryWorks({
                    category_id: curCategory as string,
                    tag_id
                })
                worksLoading.value = false
                res.data.works.forEach((work: Work) => {
                    const str = decodeURIComponent(work.scratch_file.split('/')[1].split('.')[0])
                    const _index = str.indexOf('_')
                    work.name = str.slice(_index+1)
                    work.work_image = imgPrefix + work.work_image
                });
                works.value = res.data.works
                nextTick(() => {
                    const collection = document.getElementsByClassName('tag')
                    let _tagListWidth = 0
                    for (let i = 0; i < collection.length; i++) {
                        const el = collection[i] as HTMLDivElement;
                        _tagListWidth += (el.offsetWidth + 24)
                    }
                    showHandleBtn.value = _tagListWidth > 1036
                    tagListWidth.value = _tagListWidth 
                })
                
            } catch (error) {
                console.log('error', error);
            }
        }
        const handleScroll = (flag: string) => {
            const offset = 150
            curHandleStatu.value = flag
            if (flag === '-') {
                if (tagRight.value < offset) {
                    tagRight.value = 0
                    curHandleStatu.value = ''
                    return
                }
                tagRight.value -= offset

            } else {
                tagRight.value += offset
                if (tagRight.value > (tagListWidth.value - 1036)) {
                    tagRight.value = (tagListWidth.value - 1036)
                    curHandleStatu.value = ''
                    return
                }
            }
        }
        const selectWork = (id: number | string) => {
            const url = `${target}previewAIShowWork/${id}`;
            // const url = `http://localhost:8333/previewAIShowWork/${id}`;
            window.location.href = url;
        }
        const getTags = async () => {
             try {
                let res = await getCategoryWorks({
                    category_id: curCategory as string
                })
                tagLoading.value = false
                tags.value = res.data.tags
                await fetchCategoryWorks(res.data.tags[0].tag_id)
                curTagId.value = res.data.tags[0].tag_id

            } catch (error) {
                console.log('error', error);
            }
        }
        onBeforeMount(async () => {
            getTags()
        })
        return {
            LeftOutlined,
            h,
            leftSrc,
            rightSrc,
            tags,
            curTagId,
            tagLoading,
            worksLoading,
            works,
            showHandleBtn,
            tagRight,
            tagListWidth,
            curHandleStatu,
            leftActive,
            rightActive,
            campusInfo,
            curCategoryName,
            fetchCategoryWorks,
            handleScroll,
            selectWork
        }
    }
});
</script>
<style lang="scss" scoped>
.work {
    .title {
        background: #fff;
        padding: 41px 0 31px;

        .campus {
            position: relative;
            margin-bottom: 31px;
            text-align: center;

            .back {
                padding: 4px 0;
                position: absolute;
                left: 0;
                color: var(--font-color-link);
                &:hover{
                    background-color:transparent !important;
                }
            }

            h3 {
                font-weight: 600;
                font-size: 28px;
                color: #333333;
            }
        }

        .tag-box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .last,
            .next {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                text-align: center;
                line-height: 32px;
                box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);

                img {
                    width: 14px;
                }
            }

            .last {
                position: relative;
                z-index: 2;
            }


            .right {
                position: relative;
                margin-left: 24px;
                z-index: 2;
            }
            .left-img{
                margin-right: 4px !important;
            }
            .right-img{
                margin-left: 4px !important;
            }
            .ant-spin-nested-loading {
                height: 40px;
                flex: 1;
            }
            .tag-list {
                    width: 1036px;
                    overflow: hidden;
                .tags {
                    position: relative;
                    display: flex;
                    font-size: 18px;
                    right: 0px;
                    z-index: 1;
                    transition: all 0.5s;

                    .tag {
                        padding: 11px 14px;
                        border-radius: 25px;
                        color: #333333;
                        margin: 0 12px;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                    .active {
                        background: var(--font-color-link);
                        color: #ffffff;
                    }
                }

            }
        }
    }
        .works-box {
    
            .ant-spin-nested-loading {
                height: 300px;
                .works {
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 1177px;
                    margin: 30px auto;
    
                    .work {
                        padding: 14px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        margin: 15px;
                        cursor: pointer;
    
                        &:hover {
                            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    
                            p {
                                color: var(--font-color-link);
                            }
                        }
    
                        img {
                            width: 236px;
                            height: 179px;
                            margin-bottom: 14px;
                            object-fit: cover;
                            border-radius: 4px;
                        }
    
                        p {
                            width: 236px;
                            height: 44px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 22px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            text-align: justify;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
}
</style>