<template>
    <div class="category container">
        <h3>{{ campusInfo.name }} - 图形化编程作品</h3>
        <!-- 滚动 -->
        <a-spin size="large" :spinning="categoryLoading">
            <div class="category-list">
                <a-flex align="start" wrap="wrap" :gap="30">
                    <!-- 悬浮特效 -->
                    <div v-for="category in categoryList" :key="category.category" class="category-item"
                        @click="toWorksPage(category)" :style="{ width: '31.35%' }">
                        <img :src="category.cover_image" alt="">
                        <p>{{ category.name }}</p>
                    </div>
                </a-flex>
            </div>
        </a-spin>
    </div>
</template>

<script lang="ts">

import { defineComponent, onBeforeMount, ref, reactive, getCurrentInstance, ComponentInternalInstance } from 'vue';
import { getCategorys } from '@/api/index'
import { Category } from "@/types/category"
import router from '@/router';
import store from '@/store';
import { Campus } from '@/types/category';
export default defineComponent({
    name: 'CategoryView',
    components: {
    },
    setup() {
        const app = getCurrentInstance() as ComponentInternalInstance
        const imgPrefix = app.appContext.config.globalProperties.$imgPrefix;
        const categoryList = ref<Category[]>([])
        const categoryLoading = ref<boolean>(true)
        const toWorksPage = (category: Category) => {
            store.commit('updateCategory', category.name)
            router.push(`/blocks-experience/works/${category.category}`)
        }
        const campusInfo = reactive<Campus>(store.state.campusInfo)
        onBeforeMount(() => {
            getCategorys().then(res => {
                res.data.forEach((category: Category) => {
                    category.cover_image = imgPrefix + category.cover_image
                });
                categoryList.value = res.data
                categoryLoading.value = false
            }).catch(err => {
                console.log('err', err);
            })
        })

        return {
            categoryList,
            categoryLoading,
            campusInfo,
            toWorksPage
        }
    }
});
</script>
<style lang="scss" scoped>
.category {
    .ant-spin-nested-loading {
        height: 300px;
    }

    h3 {
        font-weight: 600;
        font-size: 28px;
        color: #333333;
        text-align: center;
        margin-top: 42px;
        margin-bottom: 0;
    }

    .category-list {
        padding: 42px 55px 80px;

        .category-item {
            &:hover {
                box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);

                p {
                    color: var(--font-color-link)
                }
            }

            height: 270px;
            background: #FFFFFF;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;

            img {
                height: 224px;
                width: 100%;
                object-fit: cover;
            }

            p {
                padding: 16px;
                font-size: 14px;
                color: #333333;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}
</style>